import { Middleware } from '@nuxt/types';

import { PRINTERS_CATEGORY_UID } from '~/constants';
import getObjectByString from '~/helpers/getObjectByString';
import { Logger } from '~/helpers/logger';
import { getConfigByPageType, getProductsData, PageEntityTypeEnum } from '~/helpers/middleware/pagePreloadHelpers';
import { useLoadingStore } from '~/stores/loading';
import { usePageStore } from '~/stores/page';

const URL_SLUGS_NUMBER_FOR_PRODUCTS_CATEGORY = 3;
const URL_SLUGS_NUMBER_FOR_PAPER_PRODUCTS_CATEGORY = 2;
const PAPER_PAGE_SLUG = 'find-paper-and-labels';

const fetchData = async (context, pageStore, config) => {
  const {
    queryName, getParams,
  } = config;

  const { data, errors } = await context.app.$vsf.$magento.api[queryName](...getParams(pageStore.routeData, context.route));

  return {
    data,
    errors,
  };
};

async function getEntityResults(context, pageStore, configs, loggerMsg) {
  const promises = configs.map((config) => fetchData(context, pageStore, config));
  const { resultFn, stateKey } = configs[0];

  const promiseResults: { data: object, errors: object[] }[] = await Promise.all(promises);
  const results = promiseResults.reduce((accumulator, current) => ({ ...accumulator, ...getObjectByString(current.data, `${resultFn}`) }), {});
  Logger.debug(`middleware/page-preload/${loggerMsg}`, { data: results, errors: results[0]?.errors });

  return { results, stateKey };
}

/** Middleware for loading data before entering the next route */
const pagePreloadResolverMiddleware : Middleware = async (context) => {
  if (!process.env.IS_PAGE_PRELOAD_MIDDLEWARE) {
    return;
  }

  const pageStore = usePageStore();
  const loadingStore = useLoadingStore();

  const { routeData } = pageStore;
  const isSearchPage = context.route.name === 'search';
  let currentConfig = getConfigByPageType(isSearchPage ? null : `${routeData?.type}`);
  const categoryUid = routeData?.uid;
  const routeUrl = context.route.path.replace(/^\//, '').split('/');
  const additionalType = routeData?.uid === PRINTERS_CATEGORY_UID || isSearchPage ? PageEntityTypeEnum.CategoryProducts : `${routeData?.additionalType}`;
  const additionalConfig = additionalType ? getConfigByPageType(additionalType) : null;
  const isPaperPageListing = context.route.path.includes(PAPER_PAGE_SLUG) && routeUrl.length >= URL_SLUGS_NUMBER_FOR_PAPER_PRODUCTS_CATEGORY;
  const isListingPage = routeUrl.length >= URL_SLUGS_NUMBER_FOR_PRODUCTS_CATEGORY || isPaperPageListing;

  loadingStore.setLoading(true);

  if (isSearchPage && (!context.route.query.q || (typeof context.route.query.q === 'string' && context.route.query.q.trim().length === 0))) {
    context.error({ statusCode: 404 });
    return;
  }

  // Skip loading if parent category
  if ((routeData?.type === PageEntityTypeEnum.Category && !isListingPage) || additionalConfig) {
    // We need to delete the first element of the array because this is product query
    // which should not be run in some particular cases
    currentConfig = currentConfig?.slice(1);
  }

  // Skip loading if uid not changed (printers dynamic categories)
  if (currentConfig && !(categoryUid && pageStore[currentConfig[0].stateKey]?.uid === categoryUid)) {
    const { results, stateKey } = await getEntityResults(context, pageStore, currentConfig, 'result');
    // If it is 4-th level of category and not a printer page we need to push to one category level higher
    if (routeUrl.length === 3 && categoryUid && (!results || !Object.keys(results)?.length) && routeUrl[0] !== 'printers') {
      context.redirect(302, `/${routeUrl.slice(0, -1).join('/')}.html`);
      return;
    }

    if (!results || !Object.keys(results)?.length) context.error({ statusCode: 404 });

    pageStore.$patch((state) => {
      state[stateKey] = results;
    });
  }

  // Load additional data (products) for search and printer pages
  if (additionalConfig) {
    const { results, stateKey } = await getEntityResults(context, pageStore, additionalConfig, 'additional-result');
    const result = getProductsData(results, context.route, pageStore.routeData);

    if (isSearchPage && result?.data?.redirectUrl) {
      context.redirect(301, `${result?.data?.redirectUrl}`);
      return;
    }

    pageStore.$patch((state) => {
      state[stateKey] = results ? result : null;
    });
  }

  loadingStore.setLoading(false);
};

export default pagePreloadResolverMiddleware;
